<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>User Groups</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="newUserGroup">
          <v-icon left dark>mdi-plus</v-icon>New User Group
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-data-table
              hide-default-footer
              @item-selected="clicked"
              :single-select="singleSelect"
              v-model="selectedGroups"
              item-key="id"
              show-select
              :loading="loader"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="userGroups"
            ></v-data-table>
          </v-col>

          <v-col cols="9">
            <v-row dense>
              <v-col cols="4">
                <span style="font-size: 20px; font-weight: bold">{{
                  selectedGroup.name
                }}</span>
              </v-col>

              <v-col cols="3">
                <v-btn color="flatButton" text @click="selectUser">
                  <v-icon>mdi-plus</v-icon>Add User
                </v-btn>
              </v-col>
            </v-row>

            <v-col cols="12">
              <v-data-table
                hide-default-footer
                :headers="userHeaders"
                :items="groupUsers"
              >
                <!-- from datepicker -->
                <template v-slot:item.from="{ item }">
                  <DatePicker
                    @date="setDate"
                    :myDate="record.fromDate"
                    :title="`From`"
                  ></DatePicker>
                </template>
                <!-- end -->
                <!-- to datepicker -->
                <template v-slot:item.to="{ item }">
                  <DatePicker
                    @date="setDate"
                    :myDate="record.toDate"
                    :title="`To`"
                  ></DatePicker>
                </template>
                <!-- end -->
              </v-data-table>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="accent"
                @click="updateGroupUsers"
                :loading="updateLoader"
                >Update</v-btn
              >
            </v-col>
          </v-col>
        </v-row>

        <!-- user group creation -->
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-toolbar color="primary" dense dark>
              <v-toolbar-title>Create User Group</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="mt-2">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="record.name"
                    outlined
                    dense
                    label="Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="record.desc"
                    outlined
                    dense
                    label="Description"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <DatePicker
                    outlined
                    dense
                    @date="setFromDate"
                    :myDate="record.FromDate"
                    :title="`Active From`"
                  ></DatePicker>
                </v-col>
                <v-col cols="6">
                  <DatePicker
                    outlined
                    dense
                    @date="setToDate"
                    :myDate="record.ToDate"
                    :title="`Active To`"
                  ></DatePicker>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="record.GroupType"
                    :items="authorizations"
                    item-text="name"
                    item-value="id"
                    label="Group Type"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-btn color="accent" @click="save" :loading="loader">
                    <v-icon left>mdi-content-save</v-icon>Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of group creation -->

        <!-- user dialog -->
        <v-dialog v-model="userDialog" max-width="550px">
          <v-card>
            <v-toolbar color="primary" dense dark>
              <v-toolbar-title>User Selection</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="userDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="mt-2">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                   @change="addUser"
                    dense
                    outlined
                    v-model="newUser"
                    :items="users"
                    label="Users"
                    item-text="name"
                    item-value="id"
                    :key="event"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- user dialog -->
      </v-card-text>
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    event: "",
    singleSelect: true,
    searchInput: null,
    newUser: '',
    selectedGroups: [],
    selectedGroup: {},
    dialog: false,
    userDialog: false,
    record: {},
    loader: false,
    updateLoader: false,
    userGroups: [],
    users: [],
    groupUsers: [],
    authorizations: [
      { name: "Authorization" },
      { name: "Alerts" },
      { name: "Form Settings" },
    ],
    headers: [{ text: "Name", value: "name" }],
    userHeaders: [
      { text: "Name", value: "name" },
      { text: "From", value: "from" },
      { text: "To", value: "to" },
    ],
  }),
  methods: {
    setDate() {},
    selectUser() {
      if (this.selectedGroup.id == undefined) {
        this.$refs.snackbar.show("Please select group to update", "red");
      } else {
      this.newUser = "";
      this.userDialog = true;
      }
    },
    addUser() {
      console.log(this.newUser, "newUser")
      if (this.newUser == null) {
        this.$refs.snackbar.show("Please select user!", "red");
      } else {
        const id = this.newUser;
        const user = this.groupUsers.find((record) => {
          return record.id === id;
        });

        const addedUser = this.users.find((record) => {
          return record.id === id;
        });
        if (user == undefined) {
          this.groupUsers.push(addedUser);
          this.newUser = null;
          this.$refs.snackbar.show(`${addedUser.name} added`, "green");
          this.userDialog = false;
        } else {
          this.$refs.snackbar.show(
            `${user.name} already exist in the group`,
            "red"
          );
          // this.newUser = null;
        }
      }
    },
    clicked($event) {
      this.selectedGroup = $event.item;
      const url = `/usergroup/${$event.item.id}`;
      const self = this;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.groupUsers = res.users;
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    updateGroupUsers() {
      if (this.selectedGroup.id == undefined) {
        this.$refs.snackbar.show("Please select group to update", "red");
      } else {
        const users = this.groupUsers;
        let ids = users.reduce((op, { id }) => {
          if (typeof id !== "undefined") {
            op.push(id);
          }
          return op;
        }, []);

        const data = { users: ids };
        const id = this.selectedGroup.id;
        const url = `/usergroup/${id}`;
        const self = this;
        self.updateLoader = true;
        this.$store
          .dispatch("put", { url, data })
          .then((res) => {
            if (res.ResultCode == 1200) {
              location.reload();
            }
          })
          .catch((err) => {
            console.log(err, "err");
            // this.$refs.snackbar.show(err, "red");
          });
      }
    },
    newUserGroup() {
      this.dialog = true;
    },
    setFromDate(date) {
      this.record.FromDate = date;
    },
    setToDate(date) {
      this.record.ToDate = date;
    },
    save() {
      const data = this.record;
      const url = "/usergroup";
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getGroups() {
      const url = "/usergroup";
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.loader = false;
          self.userGroups = res.ResponseData;
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getGroups();
    this.getUsers();
  },
};
</script>